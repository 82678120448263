.card {
    display: flex;
    flex-direction: column;
    margin: 2rem 1rem 0 1rem;
    border-radius: 1rem;
    overflow: hidden;
  }
   
  .text {
    padding: 1rem 2rem;
    justify-content: space-between;
    align-items: center;
  }
  
  @media screen and (max-width: 768px) {
    .card {
      margin: 1rem 2rem;
    }
    .text {
      padding: 1rem 1.5rem;
    }
  }
  