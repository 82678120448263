
@import url('https://fonts.googleapis.com/css2?family=Nanum+Brush+Script&family=Satisfy&display=swap');



* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: rgb(50, 50, 50);
}

body {
  margin: 0% 0%;
  font-family: 'Helvetica', 'Arial', sans-serif;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.2rem;
}

h3 {
  font-size: 1rem;
  font-weight: bold;
}

h6 {
  font-size: 1rem;
}

p {
  line-height: 2rem;
  font-family: 'Helvetica', 'Arial', sans-serif;
  padding: 0rem 0rem;
  font-size: 1.2rem;
}

li{
  font-family: 'Helvetica', 'Arial', sans-serif;
  padding: 0.5rem 0rem;
  font-size: 1.2rem;
}

ol,
ul {
  padding-left: 1.5rem;
}
a {
  font-size: 1.5rem;
  text-decoration: none;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  body {
    margin: 0% 0%;
  }
}