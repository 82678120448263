.main {
    margin: 3rem 10% 0 10%;
}

.posts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-gap: 0.5rem;
}

.posts BlogCard :hover{
    background-color: black;
}

@media screen and (max-width: 768px) {
    .main {
        margin: 2rem 0 0 0;
    }
    .main .title{
        margin-left: 2rem;
    }
  }
