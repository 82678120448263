.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem 2rem 2rem 2rem;
}
.footerLogo{
    width: 3em;
    margin: 0.5rem;
}
span{
    font-size: small;
    color: gray;
}
span a{
    font-size: small;
    color: grey;
}