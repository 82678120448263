.blog {
 margin: 1em 0em;
 padding: 0 24em 0 24rem;
}

.title {
    padding-top: 1rem;
  }
  
  .title h2 {
    padding-top: 1rem;
    padding-bottom: 2rem;
    font-size: 42px;
  }
  
  .authdetails {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .authdetails img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
    object-fit: cover;
  }
  
  .authdetails h6 {
    font-weight: normal;
    color: rgb(91, 91, 91);
    margin-block: 4px;
    font-size: 16px;
  }
  
  .authtext {
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  
  .cover {
    width: 100%;
    height: 30vh;
    object-fit: cover;
  }

  .tag{
    width: fit-content;
  font-size: 12px;
  color: rgba(117, 117, 117, 1);
  background-color: rgba(242, 242, 242, 1);
  padding: 0.2em 0.5em 0.2em 0.5em;
  border-radius: 1em;
  }

  .details{
    display: flex;
  }

  .details div{
    margin-left: 8px;
  }

  
  @media screen and (max-width: 1024px) {
    .blog {
      margin: 1rem 2rem;
      padding: 0;
    }
    .content {
      width: 100%;
    }
    .content img {
      width: 100%;
    }
  }