
.book {
  margin: 0 0.5em 0 0.5em;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  padding: 3px;
  background: var(--white);
  border-radius: 1rem;
  scroll-snap-align: start;
  transition: all 0.2s;
  max-width: 15%;
}

.books {
  display: flex;
  padding: 25px 0px;
  list-style: none;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}


@media screen and (max-width: 768px) {
  .book {
    max-width: 40%;
  }
}
