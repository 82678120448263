

.hero {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 8rem 0 8rem;
	height: 50vh;
	background: linear-gradient(45deg, rgba(160,211,238,.7), rgba(100,216,243,.7), rgba(250,251,253,.7), rgba(218,206,246,.7), rgba(216,212,247,.7)) 0 0 / 1000% no-repeat, 0 0 / cover no-repeat;
	-webkit-animation: gradientAnimation 40s ease infinite;
	animation: gradientAnimation 40s ease infinite;
}
@-webkit-keyframes gradientAnimation {
	0%   { background-position: 0% 30%, 0 0;}
	50%  { background-position: 100% 70%, 0 0;}
	100% { background-position: 0% 30%, 0 0;}
}
@keyframes gradientAnimation {
	0%   { background-position: 0% 30%, 0 0;}
	50%  { background-position: 100% 70%, 0 0;}
	100% { background-position: 0% 30%, 0 0;}
}
h1 {
	font-family: 'Satisfy', cursive;
	font-size: 70px;
	font-weight: 400;
	position: absolute;
	color: rgb(37, 37, 37);
	text-align: left;
}
h1 span {
	font-family: 'Nanum Brush Script', cursive;
	display: block;
	font-size: 36px;
	font-weight: 400;
    color: rgb(37, 37, 37);
}

@media screen and (max-width: 768px) {
    h1 span {
        font-size: 26px;
    }
	h1  {
        font-size: 38px;
    }
    .hero{
        padding: 0 1em 0 1em;
		height: 26vh;
    }
  }