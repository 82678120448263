.main {
  margin: 5% 10%;
}
.book {
  display: flex;
}
.book img {
  margin: 0 2rem;
}
.details h2 {
  font-size: 36px;
}
.details h5 {
  font-size: 18px;
  margin-top: 3px;
  color: rgb(91, 91, 91);
}
.details p{
  margin-top: 1rem;
}

.details button{
  margin-top: 0.5rem;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 3rem;
  background: #fff069;
  color: darkblue;
  border: none;
  cursor: pointer;
}

.details button:hover{
  background: #ffc369;
}



@media screen and (max-width: 768px) {
    .book{
        display: flex;
        flex-direction: column;
    }
    .details h2{
        font-size: 30px;
        margin-top: 1rem;
    }
}
