nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 8rem 1rem 8rem;
}

nav ul{
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  list-style: none;
}

nav ul li{
 margin: 1em;
 font-size: 16px;
 font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
 font-weight: bold;
}

.logo {
  width: 46%;
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  nav {
    padding: 0rem 1rem;
  }
  .logo {
    width: 56%;
    margin-top: 1em;
  }
}
