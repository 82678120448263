.main{
    display: flex;
    justify-content: center;
    margin: 3rem 10% 0 10%;
}
.main form{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.editor{
    margin: 1em 0 1em 0;
    height: 300px;
    padding-bottom: 60px;
    
}


.contentEmpty{
    font-size: 16px;
    font-weight: 200;
    color: rgb(255, 66, 66);
}


label{
    font-weight: 600;
    color: #484848;
}

input , textarea, select{
    margin: 1em 0 1em 0;
    padding: 7px;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.377);
    background-color : #ffffff;
    /* box-shadow: 0 0 0 1px #dddddd, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);       */
}

.submitBtn {
    border-radius: 6px;
    /* border: 2px solid transparent; */
    border: 2px solid rgba(0, 0, 0, 0.377);
    /* box-shadow: 0 0 0 1px #dddddd, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%); */
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    height: 1em;
    width: 8em;
    height: 2.5em;
    font-size: 16px;
    font-weight: 600;
}

.submitBtn:hover{
    border: 2px solid darkslategray;
}