.card {
  display: flex;
  flex-direction: column;
  margin: 2rem 1rem 0 1rem;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.377);
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 15%); */
}
.card h2 {
  padding: 0.5rem 0rem;
  font-size: 1.1rem;
}
.card h3 {
  font-size: 0.8rem;
}

.imgContainer img {
  position: relative;
  width: 100%;
  height: 25vh;
}

.card img:hover {
  opacity: 0.8;
}

.card img {
  object-fit: cover;
  cursor: pointer;
  transition: all 0.2s ease;
}

.details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0rem;
}

.text {
  padding: 1rem 2rem;
  justify-content: space-between;
  align-items: center;
}

.text h6{
  width: fit-content;
  font-size: 12px;
  color: rgba(117, 117, 117, 1);
  background-color: rgba(242, 242, 242, 1);
  padding: 0.2em 0.5em 0.2em 0.5em;
  border-radius: 1em;
}

.author {
  display: flex;
  align-items: center;
}
.author img {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.author h3{
  font-weight: 500;
}
.date h3{
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .card {
    margin: 1rem 2rem;
  }
  .text {
    padding: 1rem 1.5rem;
  }
}
