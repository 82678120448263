.about {
  margin: 0 20% 0 20%;
}

.quote {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4rem;
}

.quote blockquote {
  font-weight: 100;
  font-size: 2rem;
  max-width: 600px;
  line-height: 1.4;
  position: relative;
  margin: 0;
  padding: 0.5rem;
}

.quote blockquote:before,
.quote blockquote:after {
  position: absolute;
  color: #d6d6d6;
  font-size: 8rem;
  width: 4rem;
  height: 4rem;
}

.quote blockquote:before {
  content: "“";
  left: -5rem;
  top: -2rem;
}

.quote blockquote:after {
  content: "”";
  right: -5rem;
  bottom: 1rem;
}

.quote cite {
  line-height: 3;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .about {
    padding: 3em 1em;
    margin: 0;
  }
  .quote blockquote {
    font-size: 1.4rem;
  }
}
